<template>
  <v-container fluid>
    <v-card class="mb-3 py-0" v-if="filter">
      <v-card-actions class="py-0 px-5">
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field v-model="search" prepend-icon="fa-search" label="Meklēt" single-line clearable/>
          </v-col>
          <v-col cols="12" md="2">
            <v-select label="Status" prepend-icon="fa-toggle-on" v-model="status" :items="statuses" item-text="text" item-value="value" clearable/>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-card>
      <v-toolbar dense flat>
        <v-toolbar-title>Reklāmas</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon :to="{name: 'advertisementCreate'}" color="primary">
          <v-icon>fa-plus</v-icon>
        </v-btn>
        <v-btn icon color="primary" @click="filter = !filter">
          <v-icon>fa-sliders-h</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="advertisements"
        item-key="item.id"
        group-by="company"
        :loading="loading"
        loading-text="Notiek ielāde... Lūdzu, uzgaidiet"
        :search="search"
        :footer-props="{
          'items-per-page-options': [10, 50, 100, -1]
        }"
        :items-per-page="50"
      >
        <template v-slot:group.header="{ group, headers, toggle, isOpen }">
          <td :colspan="headers.length">
            <v-btn dark @click="toggle" small icon :ref="group">
              <v-icon small v-if="isOpen">fa-minus</v-icon>
              <v-icon small v-else>fa-plus</v-icon>
            </v-btn>
            {{ group }}
          </td>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip small :color="statusColor(item.status)" v-html="statusText(item.status)"></v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn icon color="primary" :to="{name: 'advertisementShow', params: {id: item.id}}">
            <v-icon>fa-eye</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import axios from '@/axios'

export default {
  name: 'AdvertisementIndex',
  data: () => ({
    filter: false,
    loading: true,
    search: '',
    status: null,
    advertisements: []
  }),
  computed: {
    statuses () {
      return this.$store.state.statuses
    },
    headers () {
      return [
        {
          text: 'Statuss',
          value: 'status',
          filter: value => {
            if (this.status === 0 || this.status === 1) {
              return value === this.status
            }
            if (!this.status) return true
          }
        },
        {
          text: 'Uzņēmums',
          value: 'company',
          sortable: false
        },
        {
          text: 'Nosaukums',
          value: 'name',
          sortable: false
        },
        {
          text: 'Sākuma datums',
          value: 'date_start'
        },
        {
          text: 'Datuma beigas',
          value: 'date_end'
        },
        {
          text: 'Tips',
          value: 'type'
        },
        {
          text: 'Mērķa tips',
          value: 'target_type'
        },
        {
          text: 'Budžets',
          value: 'budget'
        },
        {
          text: 'Izveidots datums',
          value: 'created_at',
          sortable: true
        },
        {
          text: 'Darbības',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  methods: {
    getAdvertisements () {
      axios.get('advertisements').then(response => {
        this.advertisements = response.data.data
        this.loading = false
      })
    }
  },
  created () {
    this.getAdvertisements()
  }
}
</script>

<style>
.v-row-group__header {
  background: #282c96 !important;
  color: #fff !important;
}
</style>
